<template>
	<div class="h-full">
		<fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
			@onPageChange="onPageChange" :queryParam="queryParam">
			<div slot="buttonGroup" class="h-handle-button">
				<div class="h-b"><el-button type="primary" size="small" @click="exportExcel" >导出库存日志</el-button></div>
				<div style="clear: both; padding-top:10px;">
					<span class="demonstration">操作时间：</span>
					<span>
						<el-date-picker v-model="startEndTime" size="mini" type="daterange" placeholder="选择日期范围"
							:clearable="false" @change="reloadPageList()" :picker-options="pickerOptions"
							:default-time="['00:00:00', '23:59:59']">
						</el-date-picker>
					</span>
				</div>
			</div>
			<p slot="elList">
				<el-table ref="stockinfoListTable" :data="dataSource.Result" border
					@selection-change="handleSelectionChange">
					<el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams" :key="index"
						:prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam,col)"
						:fixed="index==0&&(!config||!config.isDetailDisplay)"
						:min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
						v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
						<template slot-scope="scope">
							<span> {{ scope.row[col.FieldName] }}</span>
						</template>
					</el-table-column>
				</el-table>
			</p>
		</fixed-list>
	</div>
</template>
<script>
	import sha from '../../../../../scripts/sha.js';
	export default {
		mounted() {
			this.Utils.lazy(() => {
				this.Event.$on("reloadPageList", () => this.reloadPageList());
			});
			var _this = this;
			this.initialize();
		},
		data() {
			return {
				//tableHeight: window.innerHeight-250,
				startEndTime: [new Date(), new Date()],
				multipleSelection: [],
				queryParam: {
					PageIndex: 1,
					PageSize: 10,
					Params: {
						CreatedTimeStart: null,
						CreatedTimeEnd: null,
						CreatedTime: null
					}
				},
				//今天、昨天、过去7天、过去30天、过去90天
				pickerOptions: {
					shortcuts: [{
						text: '今天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24);
							picker.$emit('pick', [end, end]);
						}
					}, {
						text: '昨天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							end.setTime(end.getTime() - 3600 * 1000 * 24);
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
							picker.$emit('pick', [end, end]);
						}
					}, {
						text: '过去7天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '过去30天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '过去90天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 89);
							picker.$emit('pick', [start, end]);
						}
					}],
					disabledDate(time) {
						return time.getTime() < Date.now() - 3600 * 1000 * 24 * 365;
					},
					onPick(date) {
						if (date.maxDate != null && date.minDate != null) {

						}
					}
				},
				dataSource: {
					ColDefs: {},
					Result: [],
					TotalCount: 0
				}
			}
		},
		props: {
			config: {
				isDetailDisplay: false
			},
			option: {}
		},
		methods: {
			reloadPageList: function() {
				this.initialize();
			},
			initialize() {
				this.onPageChange(this.queryParam);
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
				this.$emit("onCheckRow", this.multipleSelection);
			},
			onPageChange(param) {
				var _this = this;
				if (this.startEndTime.length > 0) {
					param.Params.CreatedTimeStart ={ FieldLabel: '', FieldValue: this.formatDate(this.startEndTime[0]), FieldName: 'CreatedTimeStart', FieldType: 6, FieldValueStart: "", FieldValueEnd: "" };
					param.Params.CreatedTimeEnd ={ FieldLabel: '', FieldValue: this.formatDate(this.startEndTime[1]), FieldName: 'CreatedTimeEnd', FieldType: 6, FieldValueStart: "", FieldValueEnd: "" };
					param.Params.CreatedTime ={ FieldLabel: '', FieldName: 'CreatedTime', FieldType: 6, FieldValueStart: this.formatDate(this.startEndTime[0]), FieldValueEnd: this.formatDate(this.startEndTime[1]) };
				}
				this.queryParam = param;
				console.log(this.queryParam)
				this.$ajax.query("omsapi/stockbatchinfo/searchstockbatchinfo", "post", param, data => {
					_this.dataSource = data;
				});
			},
			onDataSourceChange(ds) {
				var _this = this;

				_this.dataSource = {
					ColDefs: {
						BodyFieldParams: []
					},
					Result: [],
					TotalCount: 0
				};
				_this.$nextTick(function() {
					_this.dataSource = ds;

				});
			},
			exportStockData() {
				if (this.multipleSelection == null || this.multipleSelection.length == 0) {
					this.Utils.messageBox(`请选择需要导出的数据.`, "error");
					return false;
				}
			},
			exportExcel: function() {
				var _this = this;
				if (this.startEndTime.length > 0) {
					_this.queryParam.Params.CreatedTimeStart ={ FieldLabel: '', FieldValue: this.formatDate(this.startEndTime[0]), FieldName: 'CreatedTimeStart', FieldType: 6, FieldValueStart: "", FieldValueEnd: "" };
					_this.queryParam.Params.CreatedTimeEnd ={ FieldLabel: '', FieldValue: this.formatDate(this.startEndTime[1]), FieldName: 'CreatedTimeEnd', FieldType: 6, FieldValueStart: "", FieldValueEnd: "" };
					_this.queryParam.Params.CreatedTime ={ FieldLabel: '', FieldName: 'CreatedTime', FieldType: 6, FieldValueStart: this.formatDate(this.startEndTime[0]), FieldValueEnd: this.formatDate(this.startEndTime[1]) };

				}
				var str = encodeURIComponent(JSON.stringify(_this.queryParam));
				/*    var urlFile = "omsapi/stock/stockbatchinfo/exportstockbatchinfo?queryParam=" + str;
				    _this.Utils.export(urlFile); */


				var urlFile = "omsapi/stockbatchinfo/exportstockbatchinfo?queryParam=" + str + "&token=" + sha
					.getToken1();
				_this.Utils.export(urlFile);
			},
			formatDate(date) {
				var y = date.getFullYear();
				var m = date.getMonth() + 1;
				m = m < 10 ? ('0' + m) : m;
				var d = date.getDate();
				d = d < 10 ? ('0' + d) : d;
				var h = date.getHours();
				var minute = date.getMinutes();
				minute = minute < 10 ? ('0' + minute) : minute;
				var second = date.getSeconds();
				second = minute < 10 ? ('0' + second) : second;
				return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
			}
		},
		components: {}
	}
</script>
<style>
</style>